<template>
    <default-layout>
        <div class="spainhn-solid-red-background d-flex" style="min-height: 5vh; width:100%">
            <div class="d-flex ps-5 my-auto container">
                <p class="my-auto pb-0 mb-0 spainhn-small-text" role="button" @click="goToCastillosYPalacios">Spain
                    Heritage Network >> </p>
                <p class="my-auto pb-0 mb-0 ms-2 spainhn-small-text">POLÍTICA DE GESTION DE LA CALIDAD </p>
            </div>
        </div>
        <div class="container-fluid legal-main px-lx-5 px-1">
            <div class="container-fluid mb-5">
                <div class="row text-start margen-estandard px-lg-5">
                    <div class="d-flex flex-column pt-3 col-12 px-xl-5">
                        <p style="text-align: center;"><strong>POL&Iacute;TICA DE GESTION DE LA CALIDAD</strong></p>
                        <p style="text-align: justify;">La Direcci&oacute;n de SPAIN HERITAGE TOURS, S.L, como empresa
                            dedicada a
                            las&nbsp;actividades de <strong>&ldquo;Prestaci&oacute;n de servicios de agencia de viajes
                                online, venta
                                de&nbsp;entradas online y en taquilla, as&iacute; como desarrollo de software
                                especializado
                                en&nbsp;ventas de entradas online y taquilla, incluyendo el desarrollo de p&aacute;ginas
                                web
                                y&nbsp;la implementaci&oacute;n de acciones de comunicaci&oacute;n y
                                marketing&rdquo;</strong>, en
                            su af&aacute;n de&nbsp;mejora en el desarrollo de sus actividades, despliega su
                            pol&iacute;tica de
                            gesti&oacute;n de la&nbsp;calidad que se materializa en las siguientes
                            l&iacute;neas:<br>&bull; Tener en
                            cuenta las necesidades y requisitos marcados por nuestros clientes, con&nbsp;la principal
                            finalidad de
                            obtener su completa satisfacci&oacute;n y cubrir sus<br>expectativas.<br>&bull; Cumplir los
                            requisitos
                            legales vigentes y reglamentarios propios de nuestras&nbsp;actividades de
                            <strong>&ldquo;Prestaci&oacute;n de servicios de agencia de viajes online, venta
                                de&nbsp;entradas online
                                y en taquilla, as&iacute; como desarrollo de software especializado&nbsp;en ventas de
                                entradas
                                online y taquilla, incluyendo el desarrollo de p&aacute;ginas&nbsp;web y la
                                implementaci&oacute;n de
                                acciones de comunicaci&oacute;n y marketing&rdquo;</strong>.<br>&bull; Mejorar
                            continuamente
                            nuestros servicios, nuestras pr&aacute;cticas y procesos para&nbsp;conseguir clientes
                            m&aacute;s
                            satisfechos y un equipo plenamente comprometido con&nbsp;esta pol&iacute;tica.<br>&bull;
                            Mantener y
                            actualizar cuando sea oportuno esta pol&iacute;tica para su
                            continua&nbsp;adecuaci&oacute;n.&nbsp;
                        </p>
                        <p style="text-align: justify;">La pol&iacute;tica de gesti&oacute;n de la calidad se da a
                            conocer a todo el
                            personal de la empresa mediante comunicaciones internas de la misma, asegurando su total
                            entendimiento y
                            proporciona el marco de referencia para establecer sus objetivos. La Direcci&oacute;n de
                            SPAIN HERITAGE
                            TOURS, S.L se compromete a proporcionar los recursos materiales y humanos que sean
                            necesarios para la
                            consecuci&oacute;n de los mencionados objetivos, en el convencimiento de que el coste que
                            ello implique
                            no es otra cosa que una inversi&oacute;n rentable.</p>
                        <a href="https://spainheritagenetwork.com/files/documentos/Certificado_24.0336C.pdf" target="_blank">
                            rev 01. 24/09/2024
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
export default {
    component: {
        DefaultLayout
    },
    data() {
        return {

        }
    },
    methods:{
        goToCastillosYPalacios(){
            let $vm = this;
            $vm.$router.push('/castillos')
        }
    },

}
</script>

<style scoped>
.text-columns {
    column-count: 3;
    /* height: 50vh; */
}

.disable-selector {
    color: rgba(225, 225, 225);
}

.slide-show-container {
    position: relative;
    width: 100%;
    margin: auto;
}

.spainhn-slides {
    display: none;
}

/* Next & previous buttons */
.prev,
.next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

.legal-main li {
    width: 100%;
    overflow-wrap: break-word;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 10s;
}

@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

@media (min-width: 768px) {
    .text-columns {
        column-count: 3;
        /* height: 50vh; */
    }
}

@media (max-width: 768px) {
    .text-columns {
        column-count: 2;
        /* height: 50vh; */
    }
}

@media (max-width: 424px) {
    .text-columns {
        column-count: 1;
        /* height: 50vh; */
    }
}
</style>
