var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("default-layout", [
    _c(
      "div",
      {
        staticClass: "spainhn-solid-red-background d-flex",
        staticStyle: { "min-height": "5vh", width: "100%" },
      },
      [
        _c("div", { staticClass: "d-flex ps-5 my-auto container" }, [
          _c(
            "p",
            {
              staticClass: "my-auto pb-0 mb-0 spainhn-small-text",
              attrs: { role: "button" },
              on: { click: _vm.goToCastillosYPalacios },
            },
            [_vm._v("Spain Heritage Network >> ")]
          ),
          _c(
            "p",
            { staticClass: "my-auto pb-0 mb-0 ms-2 spainhn-small-text" },
            [_vm._v("POLÍTICA DE GESTION DE LA CALIDAD ")]
          ),
        ]),
      ]
    ),
    _c("div", { staticClass: "container-fluid legal-main px-lx-5 px-1" }, [
      _c("div", { staticClass: "container-fluid mb-5" }, [
        _c("div", { staticClass: "row text-start margen-estandard px-lg-5" }, [
          _c("div", { staticClass: "d-flex flex-column pt-3 col-12 px-xl-5" }, [
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _c("strong", [_vm._v("POLÍTICA DE GESTION DE LA CALIDAD")]),
            ]),
            _c("p", { staticStyle: { "text-align": "justify" } }, [
              _vm._v(
                "La Dirección de SPAIN HERITAGE TOURS, S.L, como empresa dedicada a las actividades de "
              ),
              _c("strong", [
                _vm._v(
                  "“Prestación de servicios de agencia de viajes online, venta de entradas online y en taquilla, así como desarrollo de software especializado en ventas de entradas online y taquilla, incluyendo el desarrollo de páginas web y la implementación de acciones de comunicación y marketing”"
                ),
              ]),
              _vm._v(
                ", en su afán de mejora en el desarrollo de sus actividades, despliega su política de gestión de la calidad que se materializa en las siguientes líneas:"
              ),
              _c("br"),
              _vm._v(
                "• Tener en cuenta las necesidades y requisitos marcados por nuestros clientes, con la principal finalidad de obtener su completa satisfacción y cubrir sus"
              ),
              _c("br"),
              _vm._v("expectativas."),
              _c("br"),
              _vm._v(
                "• Cumplir los requisitos legales vigentes y reglamentarios propios de nuestras actividades de "
              ),
              _c("strong", [
                _vm._v(
                  "“Prestación de servicios de agencia de viajes online, venta de entradas online y en taquilla, así como desarrollo de software especializado en ventas de entradas online y taquilla, incluyendo el desarrollo de páginas web y la implementación de acciones de comunicación y marketing”"
                ),
              ]),
              _vm._v("."),
              _c("br"),
              _vm._v(
                "• Mejorar continuamente nuestros servicios, nuestras prácticas y procesos para conseguir clientes más satisfechos y un equipo plenamente comprometido con esta política."
              ),
              _c("br"),
              _vm._v(
                "• Mantener y actualizar cuando sea oportuno esta política para su continua adecuación.  "
              ),
            ]),
            _c("p", { staticStyle: { "text-align": "justify" } }, [
              _vm._v(
                "La política de gestión de la calidad se da a conocer a todo el personal de la empresa mediante comunicaciones internas de la misma, asegurando su total entendimiento y proporciona el marco de referencia para establecer sus objetivos. La Dirección de SPAIN HERITAGE TOURS, S.L se compromete a proporcionar los recursos materiales y humanos que sean necesarios para la consecución de los mencionados objetivos, en el convencimiento de que el coste que ello implique no es otra cosa que una inversión rentable."
              ),
            ]),
            _c(
              "a",
              {
                attrs: {
                  href: "https://spainheritagenetwork.com/files/documentos/Certificado_24.0336C.pdf",
                  target: "_blank",
                },
              },
              [_vm._v(" rev 01. 24/09/2024 ")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }